import './App.css';
import Retool from 'react-retool';
import {useEffect, useState} from "react";
import {seo, subDomainInfo} from "./utils";


function App() {
    const [subdomain, setSubdomain] = useState('');

    useEffect(() => {
        const hostname = window.location.hostname;
        const subdomain = hostname.split('.')[0];
        const pathname = window.location.pathname;
        console.log(hostname);

        if (subdomain === 'support' || subdomain === 'b2b') {
            setSubdomain(subdomain);
            seo(subDomainInfo[subdomain])
        }
        if (hostname === 'start.pakama.co'){
            window.location.assign("https://www.pakama.com/pages/quickstart");
        }
        if (hostname === 'firmenfitness.pakama.com'){
            window.location.assign("https://www.pakama.com/pages/firmenfitness");
        }
        if (hostname === 'www.pakama.de'){
            window.location.assign(`https://www.pakama.com${pathname}`);
        }
        if (hostname === 'www.pakama-athletics.com' || hostname === 'pakama-athletics.com' ) {
            window.location.assign(`https://www.pakama.com${pathname}`);
        }
    }, []);
  return (
      // <Container maxWidth={false} disableGutters>
      // </Container>
      <div className='main-content'>
          {subdomain === 'b2b' ? (
              <Retool url={"https://retool.pakama.com/embedded/public/1131ae07-ffa4-4904-b541-3a29bfebd849"}/>
          ):null}
          {subdomain === 'support' ? (
              <Retool url={"https://pakama.retool.com/embedded/public/65c31fa0-b73d-454c-8b95-a6b5a8138d7d"}/>
          ):null}
      </div>
          )
}

export default App;
